import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import tmdb from 'lib/tmdb'
import Credit from 'components/Credit'

const CreditPage = () => {
  const { id } = useParams()
  const [credit, setCredit] = useState(null)
  const [person, setPerson] = useState(null)
  const [media, setMedia] = useState(null)
  const [images, setImages] = useState(null)
  const [otherCredits, setOtherCredits] = useState(null)

  useEffect(() => {
    tmdb.creditInfo({ id }).then((res) => {
      setCredit(res)
      tmdb.personInfo({ id: res.person.id }).then(setPerson)
      tmdb[`${res.media_type}Info`]({ id: res.media.id }).then(setMedia)
      tmdb
        .personImages({ id: res.person.id })
        .then(({ profiles }) => setImages(profiles))
      tmdb
        .personCombinedCredits({ id: res.person.id })
        .then(({ cast }) => setOtherCredits(cast))
    })
  }, [id])

  return !credit || !person || !media || !images || !otherCredits ? (
    <div>Loading...</div>
  ) : (
    <Credit
      credit={credit}
      person={person}
      media={media}
      images={images}
      otherCredits={otherCredits}
    />
  )
}

export default CreditPage
