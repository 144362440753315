import { arrayOf } from 'prop-types'
import { Link } from 'react-router-dom'
import { credit } from 'shapes'
import { getImage, getTitle } from 'lib/tmdb'
import Main from 'components/Main'
import Image from 'components/Image'

const Credits = ({ records = [] }) => {
  return (
    <Main>
      {records.map((r) => (
        <Link to={`/credits/${r.credit_id}`} key={r.credit_id}>
          <Image alt={getTitle(r)} src={getImage(r)} />
        </Link>
      ))}
    </Main>
  )
}

Credits.propTypes = {
  records: arrayOf(credit),
}

export default Credits
