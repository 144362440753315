const PALETTE = [
  'lightblue',
  'lightcoral',
  'lightcyan',
  'lightgoldenrodyellow',
  'lightgreen',
  // 'lightgrey',
  'lightpink',
  'lightsalmon',
  'lightseagreen',
  'lightskyblue',
  // 'lightslategrey',
  'lightsteelblue',
  'lightyellow',
]

export const colorFromString = (str = '') =>
  PALETTE[str.length % PALETTE.length]
