import {
  __,
  pipe,
  compact,
  first,
  get,
  identity,
  last,
  map,
  sortBy,
  subtract,
} from 'lodash/fp'
import { arrayOf, oneOfType } from 'prop-types'
import styled from '@emotion/styled/macro'
import { credit, person, movie, show, image } from 'shapes'
import { getImage, getPath, getTitle } from 'lib/tmdb'
import getYear from 'lib/getYear'
import Title from 'components/Title'
import MultiList from 'components/MultiList'
import Image from 'components/Image'
import Date from 'components/Date'

const Root = styled.div`
  overflow: hidden;
`

const ScrollingImages = styled.div`
  text-align: center;
  margin-top: 2px;
  margin-left: 2px;
  white-space: nowrap;
  overflow-x: scroll;
`

const ScrollingImage = styled(Image)`
  display: inline-block;
  margin-right: 2px;
  margin-bottom: 2px;
  text-decoration: none;
  opacity: 0.9;
`

const Character = styled.h2`
  margin-top: 4px;
  margin-bottom: 40px;
`

const DataPoints = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const DataPoint = styled.div`
  flex: 1 0 0;
  min-width: 150px;
`

const Credit = ({ credit, person, media, images, otherCredits }) => (
  <Root>
    <ScrollingImages>
      {images.map((img, i) => (
        <ScrollingImage key={i} alt={getTitle(person)} src={getImage(img)} />
      ))}
    </ScrollingImages>
    <Title>
      <h1>{person.name}</h1>
      <a href={`https://imdb.com/name/${person.imdb_id}`}>View in IMDB</a>
    </Title>
    <Character>
      as {credit.media?.character} in{' '}
      <a href={getPath(media)}>{getTitle(media)}</a>
    </Character>

    {credit.media_type === 'movie' ? (
      <DataPoints>
        <DataPoint>
          <h3>Release date</h3>
          <p>
            <Date>{media.release_date}</Date>
          </p>
        </DataPoint>
        <DataPoint>
          <h3>Age during movie</h3>
          <p>{getYear(media.release_date) - getYear(person.birthday)}</p>
        </DataPoint>
        <DataPoint>
          <h3>Born</h3>
          <p>
            <Date>{person.birthday}</Date>
          </p>
        </DataPoint>
        <DataPoint>
          <h3>Age now</h3>
          <p>{getYear() - getYear(person.birthday)}</p>
        </DataPoint>
      </DataPoints>
    ) : (
      <DataPoints>
        <DataPoint>
          <h3>Duration</h3>
          <p>
            {pipe(
              get('media.seasons'),
              map('air_date'),
              compact,
              sortBy(identity),
              first,
              getYear,
            )(credit)}{' '}
            -{' '}
            {pipe(
              get('media.seasons'),
              map('air_date'),
              compact,
              sortBy(identity),
              last,
              getYear,
            )(credit)}
          </p>
        </DataPoint>
        <DataPoint>
          <h3>Age during show</h3>
          <p>
            {pipe(
              get('media.seasons'),
              map('air_date'),
              compact,
              sortBy(identity),
              first,
              getYear,
              subtract(__, getYear(person.birthday)),
            )(credit)}{' '}
            -{' '}
            {pipe(
              get('media.seasons'),
              map('air_date'),
              compact,
              sortBy(identity),
              last,
              getYear,
              subtract(__, getYear(person.birthday)),
            )(credit)}
          </p>
        </DataPoint>
        <DataPoint>
          <h3>Born</h3>
          <p>{person.birthday}</p>
        </DataPoint>
        <DataPoint>
          <h3>Age now</h3>
          <p>{getYear() - getYear(person.birthday)}</p>
        </DataPoint>
      </DataPoints>
    )}

    <hr />

    <h3>Other credits</h3>
    <MultiList records={otherCredits} />
  </Root>
)

Credit.propTypes = {
  credit: credit.isRequired,
  person: person.isRequired,
  media: oneOfType([movie, show]).isRequired,
  images: arrayOf(image).isRequired,
  otherCredits: arrayOf(credit),
}

export default Credit
