import { MovieDb } from 'moviedb-promise'

// These methods don't come back with the media type because they assume it's
// known. This is a problem if we're passing these things to an agnostic
// function like getPath.
class Tmdb extends MovieDb {
  movieInfo = (...args) =>
    super.movieInfo(...args).then((res) => ({ ...res, media_type: 'movie' }))
  personInfo = (...args) =>
    super.personInfo(...args).then((res) => ({ ...res, media_type: 'person' }))
  tvInfo = (...args) =>
    super.tvInfo(...args).then((res) => ({ ...res, media_type: 'tv' }))
}

const { REACT_APP_TMDB_API_KEY } = process.env
const tmdb = new Tmdb(REACT_APP_TMDB_API_KEY)
if (process.env.NODE_ENV === 'development') {
  window.tmdb = tmdb
}

let config = { images: { base_url: 'http://image.tmdb.org/t/p/' } }
tmdb.configuration().then((res) => (config = res))

const PLURALS = { movie: 'movies', tv: 'shows', person: 'people' }
const pluralize = (record) => PLURALS[record?.media_type]

export const getImage = (record, fileSize = 'w185') => {
  const filePath =
    record?.poster_path || record?.profile_path || record?.file_path
  if (!filePath) return null
  const { base_url: baseUrl } = config.images
  return `${baseUrl}${fileSize}${filePath}`
}

export const getTitle = (record) => record?.title || record?.name

export const getPath = (record) => `/${pluralize(record)}/${record.id}`

export default tmdb
