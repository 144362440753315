import { string } from 'prop-types'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)

const $Date = ({ children }) => (
  <span>{children ? dayjs(children).format('LL') : null}</span>
)

$Date.propTypes = {
  children: string,
}

export default $Date
