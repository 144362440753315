import { string } from 'prop-types'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime)

const RelativeDate = ({ children }) => (
  <span>{children ? dayjs(children).fromNow() : null}</span>
)

RelativeDate.propTypes = {
  children: string,
}

export default RelativeDate
