import { BrowserRouter as Router } from 'react-router-dom'
import styled from '@emotion/styled/macro'
import Routes from './Routes'

const maxWidth = 900
const minMargin = 10

const Root = styled.div`
  max-width: ${maxWidth}px;
  margin: auto;

  @media (max-width: ${maxWidth + minMargin * 2}px) {
    margin: auto ${minMargin}px;
  }
`

const App = () => {
  return (
    <Root>
      <Router>
        <Routes />
      </Router>
    </Root>
  )
}

export default App
