import styled from '@emotion/styled/macro'

export default styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  h1 {
    margin-bottom: 4px;
  }

  a {
    flex: 0 0 auto;
  }
`
