import styled from '@emotion/styled/macro'

export default styled.div`
  margin-top: 2px;
  margin-left: 2px;
  display: flex;
  flex-wrap: wrap;

  & a {
    flex: 1 1 185px;
    margin-right: 2px;
    margin-bottom: 2px;
    max-width: calc(50% - 2px);
    text-decoration: none;
    opacity: 0.9;
  }
  & a:hover {
    opacity: 1;
  }

  & a img {
    width: 100%;
    height: 100%;
  }
`
