import { arrayOf } from 'prop-types'
import { credit, person } from 'shapes'
import { getImage, getTitle } from 'lib/tmdb'
import Title from 'components/Title'
import Credits from 'components/Credits'
import Image from 'components/Image'
import Date from 'components/Date'
import Dot from 'components/Dot'
import RelativeDate from 'components/RelativeDate'

const Person = ({ record, cast }) => (
  <div>
    <Image alt={getTitle(record)} src={getImage(record)} />
    <Title>
      <h1>{getTitle(record)}</h1>
      {record.imdb_id && (
        <a href={`https://imdb.com/title/${record.imdb_id}`}>Open in IMDB</a>
      )}
    </Title>
    <h2>
      Born: <Date>{record.birthday}</Date>
      <Dot />
      <RelativeDate>{record.birthday}</RelativeDate>
    </h2>

    <hr />
    <h3>Credits</h3>
    <Credits records={cast} />
  </div>
)

Person.propTypes = {
  record: person.isRequired,
  cast: arrayOf(credit).isRequired,
}

export default Person
