import { useState } from 'react'
import styled from '@emotion/styled/macro'
import tmdb from 'lib/tmdb'
import MultiList from 'components/MultiList'
import SearchIcon from 'images/search.svg'

const Root = styled.div`
  text-align: center;
`

const Search = styled.input`
  transition: transform 250ms ease-in-out;
  margin: 20px;
  z-index: 1;
  width: 250px;
  max-width: 45%;

  ::placeholder {
    text-align: center;
  }

  transform: ${({ empty }) =>
    empty && 'translateY(calc(50vh - 100%)) scale(2)'};

  background-color: #fdfdfc;
  background-image: url(${SearchIcon});
  background-repeat: no-repeat;
  background-position-x: 10px;
  background-position-y: center;
  padding-left: 30px;
`

const More = styled.button`
  outline: none;
  background: none;
  border: none;
  font-size: 24px;
  width: calc(100% - (2 * 2px));
  height: 60px;
  margin: 0 2px;
  overflow-anchor: none;

  :hover {
    cursor: pointer;
    background: lightgrey;
  }
`

const Home = () => {
  const [search, setSearch] = useState('')
  const [results, setResults] = useState([])
  const [page, setPage] = useState(1)
  const [more, setMore] = useState(false)

  const lookup = async (search, page, results) => {
    const { results: newResults, total_pages } = await tmdb.searchMulti({
      query: search,
      page,
    })
    setResults([...results, ...newResults])
    setMore(page < total_pages)
  }

  const handleSearchChange = (evt) => {
    const newSearch = evt.target.value
    const newPage = 1
    const newResults = []
    setMore(false)
    setSearch(newSearch)
    setPage(newPage)
    setResults(newResults)
    if (newSearch) lookup(newSearch, newPage, newResults)
  }

  const handleNextPage = async (evt) => {
    const newPage = page + 1
    setPage(newPage)
    await lookup(search, newPage, results)
  }

  return (
    <Root>
      <Search
        empty={!search}
        placeholder="What are you watching?"
        autoFocus
        value={search}
        onChange={handleSearchChange}
      />
      <MultiList records={results} />
      {more && <More onClick={handleNextPage}>More</More>}
    </Root>
  )
}

export default Home
