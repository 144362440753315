import { string } from 'prop-types'
import styled from '@emotion/styled/macro'
import { colorFromString } from 'lib/palette'

const Root = styled.div`
  text-align: center;
  height: 100%;

  .placeholder {
    background-color: lightgrey;
    height: 100%;
    min-height: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    overflow-wrap: anywhere;
  }
`

const Image = ({ src, alt, ...rest }) => (
  <Root {...rest}>
    {src ? (
      <img src={src} alt={alt}></img>
    ) : (
      <div
        className="placeholder"
        style={{ backgroundColor: colorFromString(alt) }}
      >
        {alt}
      </div>
    )}
  </Root>
)

Image.propTypes = {
  src: string,
  alt: string,
}

export default Image
