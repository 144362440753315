import { arrayOf } from 'prop-types'
import { credit, show } from 'shapes'
import { getImage, getTitle } from 'lib/tmdb'
import Title from 'components/Title'
import Credits from 'components/Credits'
import Image from 'components/Image'
import Date from 'components/Date'
import Dot from 'components/Dot'
import RelativeDate from 'components/RelativeDate'

const Show = ({ record, cast }) => (
  <div>
    <Image alt={getTitle(record)} src={getImage(record)} />
    <Title>
      <h1>{getTitle(record)}</h1>
      {record.imdb_id && (
        <a href={`https://imdb.com/title/${record.imdb_id}`}>Open in IMDB</a>
      )}
    </Title>
    <h2>
      <Date>{record.first_air_date}</Date>
      <Dot />
      <RelativeDate>{record.first_air_date}</RelativeDate>
    </h2>

    <hr />
    <h3>Cast</h3>
    <Credits records={cast} />
  </div>
)

Show.propTypes = {
  record: show.isRequired,
  cast: arrayOf(credit).isRequired,
}

export default Show
