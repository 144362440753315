import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import tmdb from 'lib/tmdb'
import Show from 'components/Show'

const ShowPage = () => {
  const { id } = useParams()
  const [record, setRecord] = useState(null)
  const [cast, setCast] = useState([])

  useEffect(() => {
    tmdb.tvInfo({ id }).then(setRecord)
    tmdb.tvCredits({ id }).then(({ cast }) => setCast(cast))
  }, [id])

  return record === null ? (
    <div>Loading...</div>
  ) : (
    <Show record={record} cast={cast} />
  )
}

export default ShowPage
