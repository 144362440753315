import { useEffect } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import Home from 'pages/Home'
import Movie from 'pages/Movie'
import Show from 'pages/Show'
import Person from 'pages/Person'
import Credit from 'pages/Credit'

const Routes = (props) => {
  const history = useHistory()

  const trackPageView = () =>
    window.gtag?.('config', 'UA-54901641-2', {
      page_path: window.location.pathname,
    })

  useEffect(() => {
    trackPageView()
    history.listen(trackPageView)
  }, [history])

  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>

      <Route exact path="/movies/:id">
        <Movie />
      </Route>

      <Route exact path="/shows/:id">
        <Show />
      </Route>

      <Route exact path="/people/:id">
        <Person />
      </Route>

      <Route exact path="/credits/:id">
        <Credit />
      </Route>

      <Route path="*">
        <h1>Not Found!</h1>
      </Route>
    </Switch>
  )
}

Routes.propTypes = {}

export default Routes
