import { arrayOf, oneOfType } from 'prop-types'
import { Link } from 'react-router-dom'
import { movie, show, person, credit } from 'shapes'
import { getImage, getPath, getTitle } from 'lib/tmdb'
import Main from 'components/Main'
import Image from 'components/Image'

const MultiList = ({ records = [] }) => {
  return (
    <Main>
      {records
        .sort((a, b) => b.popularity - a.popularity)
        .map((r) => (
          <Link to={getPath(r)} key={r.credit_id || r.id}>
            <Image alt={getTitle(r)} src={getImage(r)} />
          </Link>
        ))}
    </Main>
  )
}

MultiList.propTypes = {
  records: arrayOf(oneOfType([movie, show, person, credit])),
}

export default MultiList
