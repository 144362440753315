import React from 'react'
import ReactDOM from 'react-dom'
import { Global, css } from '@emotion/react'
import App from './App'
import reportWebVitals from './reportWebVitals'

const globalStyles = css`
  * {
    box-sizing: border-box;
    position: relative;
  }

  body {
    margin: 0 auto;
    font-family: 'Avenir Next', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
      'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #15130f;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  a {
    color: #c0b7a5;
  }

  hr {
    border: none;
    height: 1px;
    background: #eae7e11a;
  }
`

const formStyles = css`
  input {
    outline: none;
    border: solid 1px black;
    border-radius: 6px;
    padding: 10px;
    height: 40px;
  }
`

const typographyStyles = css`
  h1 {
    font-size: 40px;
    line-height: 1.5;
    color: #eae7e1;
  }

  h2 {
    font-size: 24px;
    line-height: 1.2;
    color: #c0b7a5;
  }

  h3 {
    font-size: 14px;
    line-height: 1.25;
    color: #fce465;
  }

  p {
    font-size: 16px;
    line-height: 1.5;
    color: #c0b7a5;
  }

  small {
    font-size: 12px;
    line-height: 1.25;
    color: #fce465;
  }
`

ReactDOM.render(
  <React.StrictMode>
    <Global styles={globalStyles} />
    <Global styles={formStyles} />
    <Global styles={typographyStyles} />
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
