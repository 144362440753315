import { arrayOf, number, oneOf, oneOfType, shape, string } from 'prop-types'

export const movie = shape({
  id: number.isRequired,
  media_type: oneOf(['movie']).isRequired,
  poster_path: string,
  title: string.isRequired,
  release_date: string,
})

export const show = shape({
  id: number.isRequired,
  media_type: oneOf(['tv']).isRequired,
  poster_path: string,
  name: string.isRequired,
  first_air_date: string,
})

export const person = shape({
  id: number.isRequired,
  media_type: oneOf(['person']).isRequired,
  profile_path: string,
  name: string.isRequired,
  imdb_id: string,
  birthday: string,
})

export const credit = shape({
  id: oneOfType([string, number]).isRequired,
  credit_id: string,
  media_type: oneOf(['movie', 'tv']),
  popularity: number,
  media: shape({
    id: number.isRequired,
    name: string,
    character: string,
    seasons: arrayOf(
      shape({
        air_date: string,
        season_number: number,
      }),
    ),
  }),
  person: shape({
    id: number.isRequired,
  }),
})

export const image = shape({
  file_path: string.isRequired,
})
