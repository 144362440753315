import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import tmdb from 'lib/tmdb'
import Movie from 'components/Movie'

const MoviePage = () => {
  const { id } = useParams()
  const [record, setRecord] = useState(null)
  const [cast, setCast] = useState([])

  useEffect(() => {
    tmdb.movieInfo({ id }).then(setRecord)
    tmdb.movieCredits({ id }).then(({ cast }) => setCast(cast))
  }, [id])

  return record === null ? (
    <div>Loading...</div>
  ) : (
    <Movie record={record} cast={cast} />
  )
}

export default MoviePage
